import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Selection, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip } from 'victory';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {
	Accordion,
	Button,
	ButtonStack,
	ChartLegend,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import { border, determineHeight, fontFamily, getColor, getNameOfMonth, getShortYear } from 'utils';
import { useAppSelector, useGetDelayDetailsQuery, useGetTimelyStartsQuery, useSystem } from 'store';
import { useFilters, useToast } from 'context';
import Logo from 'assets/images/branding/logo.svg';

import type { DelayDetailsResponse, TimelyStartsData } from 'store';

const legend = [
	{
		label: 'Off Target',
		color: getColor('yellow'),
	},
	{
		label: 'On Target',
		color: getColor('green'),
	},
];

const sortByOptions = [
	{
		label: 'Time (newest-oldest)',
		value: 'date_descending',
	},
	{
		label: 'Time (oldest-newest)',
		value: 'date_ascending',
	},
	{
		label: 'Rate (low-high)',
		value: 'rate_ascending',
	},
	{
		label: 'Rate (high-low)',
		value: 'rate_descending',
	},
	{
		label: '# of First Case Starts (low-high)',
		value: 'count_ascending',
	},
	{
		label: '# of First Case Starts (high-low)',
		value: 'count_descending',
	},
	{
		label: 'Alphabetical (a-z)',
		value: 'alphabetical_ascending',
	},
	{
		label: 'Alphabetical (z-a)',
		value: 'alphabetical_descending',
	},
];

const groupByOptions = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Year',
		value: 'year',
	},
	{
		label: 'Service Line',
		value: 'service_line',
	},
];

const viewByOptions = [
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Quarter',
		value: 'quarter',
	},
	{
		label: 'Surgeon',
		value: 'surgeon_id',
	},
	{
		label: 'Room',
		value: 'room_id',
	},
];

const INIT_PRINTABLE_HEADER_HEIGHT = 54;
const ROWS_PER_PAGE = 15;
const PRINTABLE_BATCH_SIZE = 18;

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { on_time_starts: number; total_first_cases: string; y: number; x: string };
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>FCOTS Rate</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.y}%</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'># of On-Time Starts</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.on_time_starts}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'># of First Cases</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.total_first_cases}</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

export interface Column {
	accessorKey: string;
	header: string;
	enableGlobalFilter?: boolean;
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: { on_time_starts: number; total_first_cases: string; y: number; x: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' dx={5} style={{ fontFamily: fontFamily, fontSize: 5 }} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function TimelyStarts() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);
	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities;
	const currentFacility = facilities?.find((f) => f.id === selectedFacility);

	const delay_reason_mapped = !(currentFacility?.field_mapping.delay_reason === '');
	if (delay_reason_mapped && !viewByOptions?.find((f) => f.value === 'delay_reason')) {
		viewByOptions.push({
			label: 'Delay Reason',
			value: 'delay_reason',
		});
	}
	const system = systemData?.healthsystems.find((h) => h.id === selectedSystem);

	// Filters
	const {
		dateRange,
		surgeons,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		procedures,
		fcotsGraceMinutes,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	const delayDetailsGroupBy =
		(dropDowns.detailGroupBy?.value !== 'undefined' ? dropDowns.detailGroupBy?.value : undefined) ?? 'month';
	const delayDetailsGroupByLabel =
		(dropDowns.detailGroupBy?.label !== 'undefined' ? dropDowns.detailGroupBy?.label : undefined) ?? 'Month';

	const delayDetailsViewBy =
		(dropDowns.detailViewBy?.value !== 'undefined' ? dropDowns.detailViewBy?.value : undefined) ?? 'surgeon_id';
	const delayDetailsViewByLabel =
		(dropDowns.detailViewBy?.label !== 'undefined' ? dropDowns.detailViewBy?.label : undefined) ?? 'Surgeon';

	// Used to hide certain elements printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? false;

	// default: view by month
	const selectedOverviewViewBy = !printable
		? dropDowns.viewBy.value !== 'undefined'
			? dropDowns.viewBy
			: viewByOptions[1]
		: {
				label: searchParams.get('view_by_label') ?? viewByOptions[1].label,
				value: searchParams.get('view_by') ?? viewByOptions[1].value,
		  };

	// default: sort by time, descending (newest data -> oldest data)
	const selectedOverviewSortBy = !printable
		? dropDowns.sortBy.value !== 'undefined'
			? dropDowns.sortBy
			: sortByOptions[0]
		: {
				label: searchParams.get('sort_by_label') ?? sortByOptions[0].label,
				value: searchParams.get('sort_by') ?? sortByOptions[0].value,
		  };
	// default: group by year
	const selectedOverviewGroupBy = !printable
		? dropDowns.groupBy.value !== 'undefined'
			? dropDowns.groupBy
			: groupByOptions[2]
		: {
				label: searchParams.get('group_by_label') ?? groupByOptions[0].label,
				value: searchParams.get('group_by') ?? groupByOptions[0].value,
		  };

	let filteredSortByOptions = sortByOptions.slice(0, sortByOptions.length - 2);
	let filteredGroupByOptions = [groupByOptions[0], groupByOptions[2]];

	let groupByArg = selectedOverviewGroupBy.value;

	// if viewby is changed, we need to limit the sortby options
	if (selectedOverviewViewBy.value === 'day_of_week') {
		filteredGroupByOptions = [groupByOptions[0], groupByOptions[1], groupByOptions[2]];
		groupByArg = groupByOptions[2].value;
	} else if (selectedOverviewViewBy.value === 'month' || selectedOverviewViewBy.value === 'quarter') {
		filteredGroupByOptions = [groupByOptions[0], groupByOptions[2]];
		groupByArg = groupByOptions[2].value;
	} else {
		filteredGroupByOptions = [groupByOptions[0]];

		groupByArg = groupByOptions[0].value;

		const options = sortByOptions.slice(2, sortByOptions.length);
		filteredSortByOptions = options;
	}

	// if the groupby changed, we need to update the sortby
	if (
		(selectedOverviewGroupBy.value === 'month' ||
			selectedOverviewGroupBy.value === 'year' ||
			selectedOverviewGroupBy.value === 'quarter' ||
			selectedOverviewGroupBy.value === 'day_of_week') &&
		groupByArg === 'none'
	) {
		const options = sortByOptions.slice(2, sortByOptions.length - 2);
		filteredSortByOptions = options;
	} else {
		const options = sortByOptions.slice(0, sortByOptions.length - 2);
		filteredSortByOptions = options;
	}

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/timely-starts') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: timelyStartsData,
		isFetching,
		error,
	} = useGetTimelyStartsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			sortby: selectedOverviewSortBy.value,
			groupby: selectedOverviewGroupBy.value,
			viewby: selectedOverviewViewBy.value,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				fcots_grace_minutes: fcotsGraceMinutes?.applied,
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	const {
		data: delayDetails,
		isFetching: delayDetailsFetching,
		error: delayDetailsError,
	} = useGetDelayDetailsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			groupby: delayDetailsGroupBy,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				fcots_grace_minutes: fcotsGraceMinutes?.applied,
			},
			viewby: delayDetailsViewBy,
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);
	const [columns, setColumns] = useState<Column[]>([]);
	const [tableData, setTableData] = useState<DelayDetailsResponse['delay_data']>([]);
	// When data from the endpoint changes or an error is encountered, update the
	// table data appropriately.
	useEffect(() => {
		if (error) {
			setColumns([]);
			setTableData([]);

			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}

			return;
		}
		// expected transforms
		const transformed_data = transformData(delayDetails, delayDetailsViewByLabel);
		setColumns(transformed_data.columns);
		setTableData(transformed_data.data);
	}, [delayDetails, error, createToast]);

	let exportData = [];
	if (timelyStartsData?.data) {
		exportData = timelyStartsData.data
			.map((group) => {
				const items = group.values.map((v) => {
					// we need any for exporting here
					// eslint-disable-next-line
					const row: any = {
						value: v.y,
					};
					const key = selectedOverviewViewBy.value.replace('_id', '');
					row[key] = v.x;
					if (selectedOverviewGroupBy.value !== 'none') {
						row[selectedOverviewGroupBy.value] = group.groupValue;
					}
					return row;
				});

				return items;
			})
			.flat();
	}

	// check the "Group by" selection in the dropdown, and do some smart grouping here based on that
	// need to "group" each type of data that can receive it
	const isGrouped = selectedOverviewGroupBy?.value !== 'none' || printable;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const data: TimelyStartsData[] = printable
		? printable_format(timelyStartsData?.data ?? [])
		: timelyStartsData?.data ?? [];
	const fcotsTarget = currentFacility?.fcots_target ?? 0;

	// Checks if printable page version, then print
	useEffect(() => {
		const generatePDF = async (rows: number) => {
			let newPage = false;
			const doc = new jsPDF('p', 'pt', 'letter');
			let prev_height = INIT_PRINTABLE_HEADER_HEIGHT;
			let rows_printed = 0;
			const header = document.getElementById(`header`);

			if (header) {
				const header_canvas = await html2canvas(header, {
					scale: 2,
				});
				const header_imgData = header_canvas.toDataURL('image/png');
				doc.addImage(header_imgData, 'PNG', 10, 10, 590, 24);
			}

			for (let i = 0; i < data.length; i += 1) {
				// Get the desired element to be captured
				const accordion = document.getElementById(`accord-${i}`);
				rows_printed += data[i].values.length;

				if (accordion) {
					// Render the content in a canvas
					const canvas = await html2canvas(accordion, {
						scale: 2,
					});

					// Add the canvas image to the PDF
					const imgData = canvas.toDataURL('image/png');

					if (newPage) {
						doc.addPage();
						newPage = false;
						prev_height = 10;
					}

					// Adding chart
					doc.addImage(imgData, 'PNG', 10, 10 + prev_height, 590, accordion.clientHeight / 2.6);
					prev_height += accordion.clientHeight / 2.7;

					if (rows_printed >= ROWS_PER_PAGE) {
						newPage = true;
						rows_printed = 0;
					}
				}
			}

			// Adding active filters
			const active_filters = document.getElementById('active-filters');
			if (active_filters) {
				const canvas_3 = await html2canvas(active_filters, {
					scale: 2,
				});
				const imgData_3 = canvas_3.toDataURL('image/png');
				doc.addPage();
				doc.addImage(imgData_3, 'PNG', 10, 10, 590, 770);
			}

			// Save the generated PDF
			doc.save('fcots.pdf');
			window.close();
		};

		if (printable && data && !(isFetching || skipRequest)) {
			setTimeout(() => {
				generatePDF(data.map((row) => row.values.length).reduce((a, b) => a + b, 0));
			}, 0);
		}
	}, [data, isFetching, isGrouped, printable, skipRequest]);

	const viewByOptionList = [
		{ label: 'Surgeon', value: 'surgeon_id' },
		{ label: 'Service Line', value: 'service_line_id' },
		{ label: 'Room', value: 'room_id' },
	];
	if (delay_reason_mapped) {
		viewByOptionList.push({ label: 'Delay Reason', value: 'delay_reason' });
	}

	return (
		<div className='relative overflow-hidden' id='overview'>
			{!printable && (
				<div className='flex justify-between'>
					<PageHeading>Timely Starts</PageHeading>
				</div>
			)}

			<div className='relative'>
				{(delayDetailsFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}
				<Panel
					title={'First Case On-Time Starts Overview'}
					tooltipContent={
						!printable
							? 'Use this visualization to determine on-time start performance across various categories, like time, surgeon, or room. In general, higher on time start performance correlates with higher intra-day schedule adherence.'
							: undefined
					}
					goToHelpID='timelystarts'
					headerContentRight={
						!printable ? (
							<>
								<div className='flex justify-end pb-3'>
									<Drawer
										metadata={metadata}
										filtersAreDirty={filtersAreDirty}
										trigger={
											<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
												<span className='material-symbol'>filter_alt</span>
												Filters
											</Button>
										}
										quickActions={[
											{
												icon: 'undo',
												onClick: resetFilters,
												tooltipText: 'Discard unapplied filter changes',
												disabled: !filtersAreDirty,
											},
											{
												icon: 'restart_alt',
												onClick: clearFilters,
												tooltipText: 'Reset filters to default',
												disabled: !metadata.saved_at,
											},
										]}
										actionButtons={[
											{
												onClick: applyFilters,
												children: 'Apply',
												disabled: !filtersAreDirty,
											},
											{
												// eslint-disable-next-line @typescript-eslint/no-empty-function
												onClick: () => {},
												children: 'Views',
												disabled: false,
											},
										]}
									>
										<FilterFields
											fields={[
												'dateRange',
												'surgeons',
												'daysOfWeek',
												'serviceLines',
												'encounterTypes',
												'rooms',
												'fcotsGraceMinutes',
												'procedures',
											]}
										/>
									</Drawer>
									<div>
										<ButtonStack>
											<Button sizeX='md' sizeY='md' variant='primary'>
												<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportData}>
													Export CSV
												</ExportButton>
											</Button>

											<Button
												sizeX='md'
												sizeY='md'
												variant='primary'
												onClick={() => {
													window.open(
														`/timely-starts?printable=true&view_by=${selectedOverviewViewBy.value}&view_by_label=${selectedOverviewViewBy.label}&sort_by=${selectedOverviewSortBy.value}&sort_by_label='${selectedOverviewSortBy.label}'&group_by_label=${selectedOverviewGroupBy.label}&group_by=${selectedOverviewGroupBy.value}`
													);
												}}
											>
												Export PDF
											</Button>
										</ButtonStack>
									</div>
								</div>
							</>
						) : (
							<div className='flex text-p1 w-screen justify-between' id='header'>
								<div className='flex text-center w-1/5'>
									<div className='pt-5 pl-8'>
										<img className='h-11 w-11 relative -left-[2px]' src={Logo} alt='Merlin' />
									</div>
									<p className='pt-5 mt-1 pl-3 w-16 whitespace-nowrap text-h5 font-semibold'>FCOTs Overview</p>
								</div>

								<div className='flex flex-row justify-end w-3/4 mt-2 pr-4'>
									<div className='px-4 py-2 pb-5 bg-blue-200 w-fit rounded-md self-center mr-6'>
										{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
									</div>
									<div className='px-4 py-2 pb-5 bg-blue-200 w-fit rounded-md self-center'>
										{`${
											currentFacility?.intraop_facility_name_alias
												? currentFacility.intraop_facility_name_alias
												: currentFacility?.name
										} - ${system?.name}`}
									</div>
								</div>
							</div>
						)
					}
					isEmpty={timelyStartsData?.data?.length === 0}
					className={`${printable && 'border-none pb-3 text-h4'}`}
				>
					<div className='flex justify-between items-center'>
						<div className='flex gap-4'>
							<div className='w-36'>
								<Select
									label='View by'
									onChange={(selected) => {
										if (selected) {
											const optionInViewBy = viewByOptions.find((element) => element.value === selected.value);
											if (optionInViewBy) {
												let newGroupBy;
												if (optionInViewBy.value === 'day_of_week') {
													newGroupBy = groupByOptions[2];
												} else if (optionInViewBy.value === 'month' || optionInViewBy.value === 'quarter') {
													newGroupBy = groupByOptions[2];
												} else {
													newGroupBy = groupByOptions[0];
												}
												dropDowns.update({ ...dropDowns, viewBy: optionInViewBy, groupBy: newGroupBy });
												saveDropdown({ ...dropDowns, viewBy: optionInViewBy, groupBy: newGroupBy });
											}
										}
									}}
									value={selectedOverviewViewBy}
									options={viewByOptions}
									defaultValue={selectedOverviewViewBy}
								/>
							</div>
							<div className='w-64'>
								<Select
									label='Sort by'
									options={filteredSortByOptions}
									value={selectedOverviewSortBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, sortBy: selected });
											saveDropdown({ ...dropDowns, sortBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Group by'
									options={filteredGroupByOptions}
									value={selectedOverviewGroupBy}
									onChange={(selected) => {
										if (selected) {
											if (filteredGroupByOptions.length > 1) {
												// if the groupby changed, we need to update the sortby
												let newSortBy;
												if (
													(selected.value === 'month' ||
														selected.value === 'year' ||
														selected.value === 'quarter' ||
														selected.value === 'day_of_week') &&
													groupByArg === 'none'
												) {
													newSortBy = sortByOptions[3];
												} else {
													newSortBy = sortByOptions[0];
												}
												dropDowns.update({ ...dropDowns, groupBy: selected, sortBy: newSortBy });
												saveDropdown({ ...dropDowns, groupBy: selected, sortBy: newSortBy });
											}
										}
									}}
								/>
							</div>
						</div>
						<div>
							<ChartLegend options={legend} />
						</div>
					</div>
					<div className='max-w-screen-2xl m-auto'>
						{isGrouped && data.length > 0 ? (
							<Accordion className='my-8' type='multiple' defaultValue={data.map((group) => `${group.groupValue}`)}>
								{data.map((group, i) => {
									return (
										<Accordion.Item key={i} value={`${group.groupValue}`} dark id={`accord-${i}`} printable={printable}>
											<TimelyBarChart values={group.values} fcotsTarget={fcotsTarget} printable={printable} />
										</Accordion.Item>
									);
								})}
							</Accordion>
						) : (
							data.map((group, i) => {
								return <TimelyBarChart key={i} values={group.values} fcotsTarget={fcotsTarget} printable={printable} />;
							})
						)}
					</div>
				</Panel>
			</div>
			<div className={`mt-9 relative ${printable && 'm-auto w-[80em]'}`} id='details'>
				{(delayDetailsFetching || skipRequest) && !printable && <LogoOverlay backgroundColor='white' />}

				<DataTable
					title={`Delay Details by ${delayDetailsGroupByLabel}`}
					tooltipContent={'This table provides a breakdown of delay reasons by Surgeon.'}
					minimalStyle={printable}
					goToHelpID='facilityutilization'
					headerContentCenter={
						<div className='flex items-center gap-8'>
							<Select
								label='View By'
								options={viewByOptionList}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										dropDowns.update({ ...dropDowns, detailViewBy: opt });
										saveDropdown({ ...dropDowns, detailViewBy: opt });
									}
								}}
								value={{ value: delayDetailsViewBy, label: delayDetailsViewByLabel }}
								// defaultValue={{ label: delayDetailsViewByLabel, value: delayDetailsViewBy }}
							/>
							<Select
								label='Group by'
								options={[
									{ label: 'Day of Week', value: 'day' },
									{ label: 'Month', value: 'month' },
									{ label: 'Year', value: 'year' },
									{ label: 'Quarter', value: 'quarter' },
								]}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										dropDowns.update({ ...dropDowns, detailGroupBy: opt });
										saveDropdown({ ...dropDowns, detailGroupBy: opt });
									}
								}}
								value={{ value: delayDetailsGroupBy, label: delayDetailsGroupByLabel }}
							/>
						</div>
					}
					headerContentCenterAlignment='left'
					disablePagination={printable}
					disableRowCounter={printable}
					columns={columns}
					data={tableData ?? []}
					rowSubview={(row) => (
						<div className='flex'>
							{1 > 0 && (
								<div className='flex'>
									<div className='bg-white p-4 pt-5 rounded-md h-fit'>
										<table>
											<colgroup span={2}></colgroup>
											<thead>
												<tr>
													<th
														colSpan={2}
														scope='colgroup'
														className='whitespace-nowrap pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
													>
														{delayDetailsViewBy === 'delay_reason' ? 'Top 5 Surgeons' : 'Top 5 Delay Reasons'}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>
														{delayDetailsViewBy === 'delay_reason' ? 'Surgeon' : 'Delay Reason'}
													</td>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Number of Delays</td>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Avg Delay Time</td>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Total Delay Minutes</td>
												</tr>
												{row.original.entity_id !== undefined &&
													delayDetails?.delay_metadata[row.original.entity_id.toString()] !== undefined &&
													delayDetails?.delay_metadata[row.original.entity_id.toString()].map((data, i) => {
														return (
															<tr key={i}>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
																	{delayDetailsViewBy !== 'delay_reason' ? data.delay_reason : data.surgeon_name}
																</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{data.num_delays}</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{`${data.avg_delay} mins`}</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{`${data.total_delay_mins} mins`}</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									</div>
								</div>
							)}
						</div>
					)}
				/>
			</div>
			{printable && (
				<div id={`active-filters`} className='h-[125em] pl-12 pt-12 pr-12'>
					<h1 className='text-h2 pb-12 font-bold'>Active Filters</h1>
					{[
						{
							name: 'Date Range',
							context: `${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`,
							type: 'static',
						},
						{ name: 'FCOTs Grace Minutes', context: `${fcotsGraceMinutes.applied} mins`, type: 'static' },
						{ name: 'Included Surgeons', context: surgeons.applied, type: 'array' },
						{ name: 'Included Rooms', context: rooms.applied, type: 'array' },
						{ name: 'Included Encounter Types', context: encounterTypes.applied, type: 'array' },
						{ name: 'Included Service Lines', context: serviceLines.applied, type: 'array' },
						{ name: 'Included Days of Week', context: daysOfWeek.applied, type: 'array' },
					].map((filter, i) => {
						return (
							<div key={i} className='pb-12 flex flex-row flex-wrap items-center'>
								<h3 className='px-4 py-2 pb-6 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>{filter.name}</h3>

								{typeof filter.context == 'object' ? (
									<div className='flex flex-row flex-wrap'>
										{filter.context.slice(0, 40).map((option, i) => (
											<div
												key={i}
												className='px-4 py-2 pb-4 mr-2 border border-blue-300 text-p2 w-fit rounded-md mt-6 self-center'
											>
												{typeof option == 'string' ? option : option.name}
											</div>
										))}
										{filter.context.slice(0, 40).length === 40 && (
											<div className='px-4 py-2 pb-4 mr-2 font-semibold text-p2 w-fit rounded-md mt-6 self-center'>{`+ ${
												filter.context.length - filter.context.slice(0, 40).length
											} ....`}</div>
										)}
									</div>
								) : (
									<p className='pt-2'>{`${filter.context}`}</p>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default TimelyStarts;

interface TimelyBarChartsProps {
	values: TimelyStartsData['values'];
	fcotsTarget: number;
	printable?: boolean;
}

function TimelyBarChart({ values, fcotsTarget, printable }: TimelyBarChartsProps) {
	const axisFontSize = 5;
	const valuesAxis = {
		axis: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: axisFontSize, padding: 5 },
	};

	const viewByAxis = {
		axis: { stroke: border },
		grid: { stroke: border },
		tickLabels: { fontFamily: fontFamily, fontSize: axisFontSize, padding: 5 },
	};

	const dataMaxY = (values && Math.ceil(Math.max(...values.map((d) => d.y)) + 10)) ?? 100;
	const avg = Math.round(values.reduce((r, c) => r + c.x.toString().length, 0) / values.length);
	const regx = new RegExp('.{1,avg}(?:\\s|$)'.replace('avg', avg > 20 ? (avg - 2).toString() : '18'), 'g');
	return (
		<VictoryChart
			height={determineHeight(values.length)}
			padding={{ top: 15, bottom: 15, left: avg > 20 ? avg * 3 : 65, right: 10 }}
			domain={{ y: [0, dataMaxY < 100 ? 100 : dataMaxY] }}
			horizontal
			domainPadding={10}
		>
			<VictoryAxis offsetY={12} dependentAxis style={viewByAxis} tickFormat={(t) => `${t}%`} />
			<VictoryAxis style={valuesAxis} tickFormat={(t) => (t.match(regx).length > 1 ? t.match(regx) : t)} />

			<VictoryBar
				data={values}
				// we noticed VictoryBar was not preserving the sort order from the endpoint so we are sorting values here
				// we are sorting values one way in the backend and sorting again here...maybe we can get around this behavior in the future
				sortKey='x'
				sortOrder='descending'
				cornerRadius={2}
				barWidth={12.5}
				horizontal
				style={{
					data: {
						fill: ({ datum }) => {
							return datum.y < fcotsTarget ? getColor('yellow') : getColor('green');
						},
					},
				}}
				labels={values.map((v) => `${v.y}% ${printable ? ` | ${v.total_first_cases} First Cases` : ''}`)}
				labelComponent={<CustomLabel />}
			/>
			<VictoryLine
				y={() => fcotsTarget}
				style={{
					data: { stroke: '#000033', strokeWidth: 0.25, strokeDasharray: 1 },
					parent: { border: '0.5px solid #ccc' },
				}}
			/>
			<VictoryAxis
				dependentAxis
				orientation='top'
				tickValues={[fcotsTarget]}
				tickFormat={(targetPercent) => `Target - ${Math.round(targetPercent)}%`}
				style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 0 } }}
				tickLabelComponent={
					<VictoryLabel
						lineHeight={[1.75, 1.25, 1.25]}
						style={{ fontSize: 4, fontFamily: fontFamily, fontWeight: 'bold' }}
					/>
				}
				animate
			/>
		</VictoryChart>
	);
}

interface PrintableTable {
	g: string | number | null | undefined;
	value: {
		x: string | number;
		y: number;
		on_time_starts?: number | undefined;
		total_first_cases?: number | undefined;
	};
}

function printable_format(data: TimelyStartsData[]) {
	const bigArray: PrintableTable[][] = [];
	for (let i = 0; i < data.length; i++) {
		const array = batch_array(
			data[i].values.map((d) => ({ g: data[i].groupValue, value: d })),
			PRINTABLE_BATCH_SIZE
		);

		array.forEach((d) => bigArray.push(d));
	}

	const response: TimelyStartsData[] = [];

	for (let i = 0; i < bigArray.length; i++) {
		const values: {
			x: string | number;
			y: number;
			on_time_starts?: number | undefined;
			total_first_cases?: number | undefined;
		}[] = [];
		for (let j = 0; j < bigArray[i].length; j++) {
			values.push(bigArray[i][j].value);
		}
		response.push({ groupValue: bigArray[i][0].g, values });
	}

	return response;
}

function batch_array(bigarray: PrintableTable[], batch_size: number) {
	const size = batch_size;
	const arrayOfArrays = [];
	for (let i = 0; i < bigarray.length; i += size) {
		arrayOfArrays.push(bigarray.slice(i, i + size));
	}
	return arrayOfArrays;
}

interface DateMap {
	[key: string]: {
		[key: number]: number | string;
	};
}

interface ColumnMap {
	[key: string]: string;
}

interface DelayDetails {
	[key: number]: {
		[key: string]: number | string;
		entity_id: number;
		name: string;
		service_line: string;
	};
}

// Transforming response to display in datatable
function transformData(data?: DelayDetailsResponse, viewBy?: string) {
	let columns: Column[] = []; // columns to be displayed
	const dateMapping: DateMap = {}; // bucket surgeons by date
	const prettyDateColumns: ColumnMap = {}; // stores date to pretty date mapping e.g. 2022-09-26 -> Mon
	const entityData: DelayDetails = {}; // stores final data format for datatable

	// Group surgeons by date and create seen surgeons object
	data?.delay_data.forEach((row) => {
		const date = row.date;

		if (dateMapping[date] !== undefined) {
			dateMapping[date][row.entity_id] = row.num_delays;
		} else {
			dateMapping[date] = {};
			dateMapping[date][row.entity_id] = row.num_delays;
		}

		if (entityData[row.entity_id] === undefined) {
			entityData[row.entity_id] = {
				entity_id: row.entity_id,
				name: row.name,
				service_line: row.service_line,
			};
		}
	});

	// Set values for filter daterange
	// API sends volume_data in date ascending order
	const dateRange = {
		start: data?.start_date ?? '',
		end: data?.latest_date ?? '',
	};

	// Create pretty mapping for dates 2022-01-01 -> Jan'22
	for (const dateGroup in dateMapping) {
		switch (data?.group_by) {
			case 'day_of_week':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'month':
				prettyDateColumns[dateGroup] = `${getNameOfMonth(`${dateGroup}-01`)}'${getShortYear(`${dateGroup}-01`)}`;
				break;
			case 'year':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'quarter':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'encounter_type':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
		}
	}

	// Set pretty column names
	columns.push({
		accessorKey: 'name',
		header: viewBy ?? '',
	});

	// DOW require special columns order
	const possibleDow = [
		{ accessorKey: 'Sun', header: 'Sun', enableGlobalFilter: false },
		{ accessorKey: 'Mon', header: 'Mon', enableGlobalFilter: false },
		{ accessorKey: 'Tue', header: 'Tue', enableGlobalFilter: false },
		{ accessorKey: 'Wed', header: 'Wed', enableGlobalFilter: false },
		{ accessorKey: 'Thur', header: 'Thur', enableGlobalFilter: false },
		{ accessorKey: 'Fri', header: 'Fri', enableGlobalFilter: false },
		{ accessorKey: 'Sat', header: 'Sat', enableGlobalFilter: false },
	];
	if (data?.group_by === 'day') {
		// Contains dow sent from endpoint
		const filteredDow = Object.keys(prettyDateColumns);
		columns = [
			{
				accessorKey: 'name',
				header: viewBy ?? '',
			},
		];

		for (const column of possibleDow) {
			if (filteredDow.includes(column.accessorKey)) {
				columns.push(column);
			}
		}
	} else {
		for (const dateGroup in prettyDateColumns) {
			columns.push({
				accessorKey: dateGroup,
				header: prettyDateColumns[dateGroup],
				enableGlobalFilter: false,
			});
		}
	}

	// Create rows that are readable by datatables
	for (const entity in entityData) {
		for (const date in dateMapping) {
			const volume = dateMapping[date][entity] ?? 0;
			entityData[entity][date] = volume;
		}
	}

	return {
		columns: columns,
		data: Object.values(entityData),
		date_range: dateRange,
	};
}
