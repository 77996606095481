import React, { useEffect, useState } from 'react';
import { Selection, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTooltip } from 'victory';
import { format } from 'date-fns';

import {
	Button,
	Drawer,
	ExportButton,
	FilterFields,
	LegacyHeatmap,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import { determineBarWidth, determineDomainPadding, dynamicFontSizeStyles } from 'utils';
import { useAppSelector, useGetSchedulingPatternsQuery } from 'store';
import { useFilters, useToast } from 'context';

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { total_addons: number; total_days: number; y: number; x: string };
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Avg Add-On Volume</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.y}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Total Add-Ons</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.total_addons}</p>
					</div>
					<div className='bg-blue-900 p-1 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Total Days</p>
						<p className='text-right text-[0.25em] text-white'>{datum?.total_days}</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: { total_addons: number; total_days: number; y: number; x: string };
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		const dynamicStyles = dynamicFontSizeStyles();
		const axisLabelStyle = dynamicStyles.axisLabelStyle;
		return (
			<g>
				<VictoryLabel {...this.props} verticalAnchor='middle' style={axisLabelStyle} />
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function SchedulingPatterns() {
	const {
		dateRange,
		surgeons,
		daysOfWeek,
		serviceLines,
		rooms,
		procedures,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/scheduling-patterns') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const { createToast } = useToast();
	const viewBy = (dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.value : undefined) ?? 'day_of_week';
	const viewByLabel = (dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.label : undefined) ?? 'Day of Week';
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data, isFetching, isError, error } = useGetSchedulingPatternsQuery(
		{
			facility_id: selectedFacility,
			groupby: viewBy,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
		if (data?.data.length && data?.data?.length > 12) {
			createToast({
				title: 'The group by has been changed to show the maximum amount of data possible for the date range selected.',
				variant: 'info',
			});
			dropDowns.update({ ...dropDowns, viewBy: { label: 'Year', value: 'year' } });
			saveDropdown({ ...dropDowns, viewBy: { label: 'Year', value: 'year' } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createToast, error, data, isError]);

	const exportData =
		data?.data.map((value) => ({
			[viewBy]: value.x,
			average_add_ons: value.y,
		})) ?? [];

	// Dynamic font sizing
	const dynamicStyles = dynamicFontSizeStyles();
	const timeAxisStyles = dynamicStyles.timeAxisStyles;
	const xAxisStyles = dynamicStyles.generalAxisStyles;
	const smallerAxisLabelStyle = dynamicStyles.smallerAxisLabelStyle;

	const max_y_domain = Math.max(...(data?.data.map((value) => value.y) ?? [10]));

	return (
		<div>
			<PageHeading>Scheduling Patterns</PageHeading>
			<div className='flex justify-end pb-3'>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields fields={['dateRange', 'daysOfWeek', 'serviceLines', 'surgeons', 'rooms', 'procedures']} />
				</Drawer>
				<div>
					<ExportButton contents={exportData} />
				</div>
			</div>
			<div className='relative'>
				{(isFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}

				<Panel
					title='Average Daily Add-On Volume'
					tooltipContent={`Use this visualization to determine which temporal categories have higher average add-on rates, like certain days of the week. Higher add-on volume can correlate with lower intra-day schedule predictability.`}
					goToHelpID='schedulingpatterns'
					isEmpty={data?.data.length === 0}
				>
					<Select
						label='View By'
						options={[
							{ label: 'Day of Week', value: 'day_of_week' },
							{ label: 'Month', value: 'month' },
							{ label: 'Quarter', value: 'quarter' },
							{ label: 'Year', value: 'year' },
						]}
						sizeX='sm'
						onChange={(opt) => {
							if (opt) {
								dropDowns.update({ ...dropDowns, viewBy: opt });
								saveDropdown({ ...dropDowns, viewBy: opt });
							}
						}}
						value={{ label: viewByLabel, value: viewBy }}
					/>

					<div className='max-w-screen-2xl m-auto'>
						<VictoryChart
							domainPadding={determineDomainPadding(data?.data.length ?? 0)}
							height={200}
							minDomain={{ y: 0 }}
							maxDomain={{ y: max_y_domain * 1.5 }}
							padding={{ top: 30, bottom: 35, right: 30, left: 50 }}
						>
							{/* Y-Axis */}
							<VictoryAxis
								tickFormat={(t) => `${t.toFixed(1)}`}
								sortOrder='descending'
								dependentAxis
								padding={{ left: 20 }}
								style={timeAxisStyles}
							/>
							{/* X-Axis */}
							<VictoryAxis style={xAxisStyles} standalone={false} label={viewByLabel} />
							<VictoryBar
								style={{
									data: {
										fill: '#6699ff',
									},
								}}
								data={data?.data ?? []}
								barWidth={determineBarWidth(data?.data.length ?? 0)}
								cornerRadius={2}
								labels={({ datum }) => datum.y}
								labelComponent={<CustomLabel />}
							/>
							<VictoryLabel text='Average' x={10} y={80} style={smallerAxisLabelStyle} />
							<VictoryLabel text='Daily' x={10} y={85} style={smallerAxisLabelStyle} />
							<VictoryLabel text='Add-On' x={10} y={90} style={smallerAxisLabelStyle} />
							<VictoryLabel text='Volume' x={10} y={95} style={smallerAxisLabelStyle} />
						</VictoryChart>
					</div>
				</Panel>
				<div className='h-8'></div>
				<Panel
					title='Add-On Start Times'
					tooltipContent={
						'Use this heatmap to better understand when add-on cases are likely to start based on patient in room time.'
					}
					goToHelpID='schedulingpatterns'
					headerContentRight={
						<div className='inline-flex items-center justify-between relative drop-shadow border border-blue-500 rounded text-blue-900'>
							<span className='text-b3 font-semibold py-4 px-6'>
								{`${format(dateRange.applied.startDate, 'M/d/yyyy')} – ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
							</span>
						</div>
					}
				>
					<div className='max-w-screen-2xl m-auto'>
						<LegacyHeatmap
							data={data?.heatmap ?? []}
							scaleLowerBound={-1}
							axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
							emptyMessage='This heatmap has no data to display based on the provided filters.'
							colorScheme='magma'
						/>
					</div>
				</Panel>
			</div>
		</div>
	);
}

export default SchedulingPatterns;
