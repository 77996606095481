import React, { useEffect, useState } from 'react';
import {
	LineSegment,
	Selection,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryStack,
	VictoryTooltip,
} from 'victory';
import { addMonths, format, isToday } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { renderToStaticMarkup } from 'react-dom/server';

import Logo from 'assets/images/branding/logo.svg';
import {
	Button,
	ButtonStack,
	ChartLegend,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import {
	border,
	convertDOWNumericToString,
	convertMonthNumericToString,
	determineBarWidth,
	determineDomainPadding,
	determineHeight,
	dynamicFontSizeStyles,
	getColor,
	getFacility,
	shortDOWToNumber,
} from 'utils';
import { useAppSelector, useSystem } from 'store';
import { useFilters, useToast } from 'context';
import { Facility } from 'models';
import { normalize } from 'pages/Settings/Tabs/BlockSettings';
import { useGetBlockFillRateQuery } from 'store/services/BlockService';

interface FacilityUtilizationChartDatum {
	x: string;
	y: number;
	allocated_minutes: number;
	scheduled_in_block_minutes: number;
	estimated_add_on_minutes: number;
}

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: {
		allocated_minutes: number;
		scheduled_in_block_minutes: string;
		estimated_add_on_minutes: number;
		y: number;
		x: string;
	};
}> {
	render() {
		const { x2, y2, datum } = this.props;
		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-52'>
					<div className='bg-white p-2 px-3 pl-1'>
						<p className='text-left text-[.9em] font-semibold'>{datum?.x}</p>
					</div>
					<div className='bg-blue-900 p-2 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.75em] text-white'>Scheduled Hours</p>
						<p className='text-right text-[0.75em] text-white'>{datum?.scheduled_in_block_minutes}</p>
					</div>
					<div className='bg-blue-900 p-2 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.75em] text-white'>Est. Add-On Hours</p>
						<p className='text-right text-[0.75em] text-white'>{datum?.estimated_add_on_minutes}</p>
					</div>
					<div className='bg-blue-900 p-2 flex justify-between'>
						<p className='whitespace-nowrap text-left text-[0.75em] text-white'>Allocated Hours</p>
						<p className='text-right text-[0.75em] text-white'>{datum?.allocated_minutes}</p>
					</div>
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: {
		allocated_minutes: number;
		scheduled_in_block_minutes: string;
		estimated_add_on_minutes: number;
		y: number;
		x: string;
	};
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2 } = this.props;
		const dynamicStyles = dynamicFontSizeStyles();
		const axisLabelStyle = dynamicStyles.axisLabelStyle;
		return (
			<g>
				<VictoryLabel
					{...this.props}
					verticalAnchor='middle'
					style={{
						fontFamily: 'inter',
						fontSize: 12,
						fontWeight: 700,
					}}
				/>
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

const colors = {
	YELLOW: '#ffa42e',
	BLUE: '#106ff4',
	GRAY: '#f2f2f2',
};

const legend = [
	{
		label: 'Scheduled In Block Hours',
		color: colors.YELLOW,
	},
	{
		label: 'Est. Add-On Hours',
		color: colors.BLUE,
	},
	{
		label: 'Allocated Hours',
		color: colors.GRAY,
	},
];

export function BlockFillRate() {
	// Filters
	const {
		blockName,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// display state
	const startDate = new Date();
	const endDate = addMonths(startDate, 5);
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);
	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities ?? [];
	const facility = systemData?.facilities?.find((f) => f.id === selectedFacility);
	const system = systemData?.healthsystems.find((h) => h.id === selectedSystem);
	//get target based on facility settings
	const target = facility?.facility_utilization_target ?? 0;

	// Used to hide certain elements printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? false;
	const view_by_param =
		searchParams.get('view_by') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.value : undefined) ??
		'day_of_week';
	const view_by_label_pararm =
		searchParams.get('view_by_label') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.label : undefined) ??
		'Day of Week';

	const viewBy = view_by_param;
	const viewByLabel = view_by_label_pararm;
	const { createToast } = useToast();
	const [chartData, setChartData] = useState<FacilityUtilizationChartDatum[]>([]);
	const [numBars, setNumBars] = useState<number>(0);

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/facility-utilization') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const { data: fillRateOverview, isFetching } = useGetBlockFillRateQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			block_id: blockName?.applied.id,
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	const values = fillRateOverview?.block_fill_rate_with_add_on_proxy ?? [];

	const scheduledCases = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: obj.label,
			y: Math.round(obj.scheduled_in_block_minutes / 60),
			max: Math.round(obj.allocated_minutes / 60),
			allocated_minutes: Math.round(obj.allocated_minutes / 60),
			scheduled_in_block_minutes: Math.round(obj.scheduled_in_block_minutes / 60),
			estimated_add_on_minutes: Math.round(obj.estimated_add_on_minutes / 60),
		};
	});

	const avgAddOn = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: obj.label,
			y: Math.round(obj.estimated_add_on_minutes / 60),
			max: Math.round(obj.allocated_minutes / 60),
			total_minutes: Math.round(
				((obj.scheduled_in_block_minutes + obj.estimated_add_on_minutes) / obj.allocated_minutes) * 100
			),
			allocated_minutes: Math.round(obj.allocated_minutes / 60),
			scheduled_in_block_minutes: Math.round(obj.scheduled_in_block_minutes / 60),
			estimated_add_on_minutes: Math.round(obj.estimated_add_on_minutes / 60),
		};
	});

	const allocatedMins = values.map((obj, i) => {
		return {
			row_name: obj.label,
			x: obj.label,
			y:
				obj.scheduled_in_block_minutes + obj.estimated_add_on_minutes <= obj.allocated_minutes
					? Math.round((obj.allocated_minutes - (obj.scheduled_in_block_minutes + obj.estimated_add_on_minutes)) / 60)
					: 0,
			max: Math.round(obj.allocated_minutes / 60),
			allocated_minutes: Math.round(obj.allocated_minutes / 60),
			scheduled_in_block_minutes: Math.round(obj.scheduled_in_block_minutes / 60),
			estimated_add_on_minutes: Math.round(obj.estimated_add_on_minutes / 60),
		};
	});
	const maxHour2 = fillRateOverview?.block_fill_rate_with_add_on_proxy.map((res_obj, i) => res_obj.allocated_minutes);
	const maxHour = Math.max(...(maxHour2 ?? [1])) * 1.2;
	const avg = Math.round(scheduledCases.reduce((r, c) => r + c.x.toString().length, 0) / values.length);
	const regx = new RegExp('.{1,avg}(?:\\s|$)'.replace('avg', avg > 20 ? (avg - 2).toString() : '18'), 'g');

	const fill_rate_arr = fillRateOverview?.block_schedule_details.map((x) => x.fill_rate) ?? [];

	// note: the target percent is hard coded to 70 here
	const avg_fill_rate =
		fill_rate_arr?.filter((x) => x >= 0).reduce((a, b) => a + b, 0) / fill_rate_arr?.filter((x) => x >= 0).length;

	const fill_rate_background_cell_color = 'bg-gray-50';

	return (
		<div>
			{!printable && (
				<PageHeading>{blockName.applied.id !== 0 ? `${blockName?.applied.name ?? ''} Block Fill Rate` : ''}</PageHeading>
			)}

			{(isFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}

			<div className='flex justify-end pb-3'>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields fields={['blockName']} />
				</Drawer>
				<div>
					{allocatedMins.length > 0 && (
						<ButtonStack>
							<Button sizeX='md' sizeY='md' variant='primary'>
								<ExportButton
									no_button={true}
									sizeX='md'
									sizeY='md'
									variant='primary'
									contents={fillRateOverview?.block_schedule_details ?? []}
								>
									Export CSV
								</ExportButton>
							</Button>

							<Button
								sizeX='md'
								sizeY='md'
								variant='primary'
								onClick={() => {
									window.open(`/facility-utilization?printable=true&view_by=${viewBy}&view_by_label=${viewByLabel}`);
								}}
							>
								Export PDF
							</Button>
						</ButtonStack>
					)}
				</div>
			</div>
			{blockName.applied.id === 0 && (
				<React.Fragment>
					<img className='h-16 w-16 block m-auto mt-32' src={Logo} alt='Merlin' />
					<p className='text-p1 text-center mt-3'>Please select a block using the filters button.</p>
				</React.Fragment>
			)}
			{blockName.applied.id !== 0 && (
				<Panel
					title={'Dashboard'}
					tooltipContent={
						!printable
							? 'Use this visualization to understand overall utilization rates across various temporal categories. In general, higher  primetime utilization correlates with more efficient operating room usage during peak hours.'
							: undefined
					}
					isEmpty={allocatedMins.length < 1}
					className={`${printable && ' text-h4 border border-red-200 w-screen'}`}
					noHeader={printable}
				>
					<div className='flex pb-0 mb-0 w-100'>
						<div>
							<p className='px-3 text-left mr-2 mb-3 py-2 flex items-center justify-left gap-2'>
								<span className='font-semibold'>{`Upcoming Block Schedule with Expected Add-Ons`}</span>
								<ReactTooltip content={`Use this visualization to track the overall block utilization trend.`}>
									<div className='material-symbol-sm text-blue-500'>info</div>
								</ReactTooltip>
							</p>
							<ChartLegend options={legend} />
							<div>
								<VictoryStack
									colorScale={[colors.YELLOW, colors.BLUE, colors.GRAY]}
									style={{ labels: { fontSize: 24, fontFamily: 'inter' } }}
									height={window.innerHeight / 2.4}
									width={window.innerWidth}
									// height={determineHeight(values.length, true)}
									// width={700}
									domain={{ x: [0.5, values.length + 0.5], y: [0, (maxHour / 60) * 1.15] }}
									padding={{ top: 15, bottom: 30, right: 10, left: avg > 20 ? avg * 2.8 : 60 }}
								>
									<VictoryBar
										data={scheduledCases}
										barWidth={30}
										labels={({ datum }) => ` `}
										style={{
											labels: { fontSize: 24, fontFamily: 'inter', fontWeight: 600, fill: 'rgb(16, 111, 244)' },
										}}
										labelComponent={<CustomLabel />}
									/>
									<VictoryBar
										data={avgAddOn}
										labels={({ datum }) => `${datum.total_minutes}%`}
										style={{
											labels: { fontSize: 24, fontFamily: 'inter', fontWeight: 600, fill: 'rgb(255, 255, 255)' },
										}}
										barWidth={30}
										cornerRadius={3}
										labelComponent={<CustomLabel />}
									/>
									<VictoryBar
										data={allocatedMins}
										labels={({ datum }) => ``}
										barWidth={30}
										cornerRadius={3}
										labelComponent={<CustomLabel />}
									/>
									<VictoryAxis
										standalone
										tickValues={values.map((obj) => String(obj.label))}
										tickLabelComponent={<VictoryLabel style={{ fontSize: 10, fontFamily: 'inter' }} />}
										gridComponent={<LineSegment style={{ stroke: border, padding: { top: 100 } }} />}
										axisComponent={<LineSegment style={{ stroke: border }} />}
									/>
									<VictoryAxis
										dependentAxis
										tickLabelComponent={<VictoryLabel style={{ fontSize: 10, fontFamily: 'inter' }} />}
										axisComponent={<LineSegment style={{ stroke: border }} />}
										tickFormat={(x) => `${x} hrs`}
										padding={{ top: 0 }}
										gridComponent={<LineSegment style={{ stroke: border, padding: { bottom: 100 } }} />}
										orientation='left'
									/>
								</VictoryStack>
							</div>
						</div>
					</div>
				</Panel>
			)}
			<div className='py-4'></div>
			{blockName.applied.id !== 0 && (
				<DataTable
					title='Block Fill Rate Details'
					keepPageIndexOnLeave={'block_details'}
					tooltipContent={`This table provides a breakdown of the components that factor into block utilization, like in block minutes, turnover, and allocated time. It also includes other helpful values like out-block minutes, case volume, flips, and add-ons. Click on a row to see specific case details for the day.`}
					goToHelpID='commonDefinitions'
					columns={[
						{
							header: 'Date of Surgery',
							accessorKey: 'date',
							cell: ({ row }) => <span className='py-1 text-p2 text-center'>{row.original.date.toLocaleString('en-US')}</span>,
							sortingFn: (rowA, rowB, columnId) => {
								const a = new Date(rowA.getValue(columnId)).getTime();
								const b = new Date(rowB.getValue(columnId)).getTime();
								return b > a ? 1 : -1;
							},
							enableGlobalFilter: false,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Dow',
							accessorKey: 'day_of_week',
							enableGlobalFilter: false,
							sortingFn: (rowA, rowB) => {
								const a = shortDOWToNumber(rowA.original.day_of_week);
								const b = shortDOWToNumber(rowB.original.day_of_week);
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Scheduled In Block Minutes',
							accessorKey: 'scheduled_in_block_minutes',
							enableGlobalFilter: false,
							cell: ({ row }) => `${row.original.scheduled_in_block_minutes}m`,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Scheduled Out Block Minutes',
							accessorKey: 'scheduled_out_block_minutes',
							enableGlobalFilter: false,
							cell: ({ row }) => `${row.original.scheduled_out_block_minutes}m`,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Scheduled Turnover Minutes',
							accessorKey: 'scheduled_turnover_minutes',
							enableGlobalFilter: false,
							cell: ({ row }) => `${row.original.scheduled_turnover_minutes}m`,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Scheduled Cases',
							accessorKey: 'number_of_scheduled_cases',
							enableGlobalFilter: false,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Allocated Minutes',
							accessorKey: 'allocated_minutes',
							enableGlobalFilter: false,
							cell: ({ row }) => `${row.original.allocated_minutes}m`,
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Number of Rooms',
							accessorKey: 'number_of_rooms',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								return <p>{row.original.number_of_rooms}</p>;
							},
							meta: {
								headerClass: 'whitespace-pre-line',
							},
						},
						{
							header: 'Fill Rate',
							accessorKey: 'fill_rate',
							enableGlobalFilter: false,
							cell: ({ row }) => {
								let color = '';
								switch (true) {
									case row.original.fill_rate < 40:
										color = 'text-red-500 text-center';
										break;
									case row.original.fill_rate < 75:
										color = 'text-yellow-500 text-center';
										break;
									default:
										color = 'text-green-500 text-center';
										break;
								}
								const isValueGiven = row.original.fill_rate >= 0;

								if (!isValueGiven) {
									color = 'text-gray-300 text-center';
								}
								return <p className={color}>{isValueGiven ? `${row.original.fill_rate}%` : '---'}</p>;
							},
							meta: {
								bodyClass: fill_rate_background_cell_color,
								footerClass: fill_rate_background_cell_color,
								headerClass: `${fill_rate_background_cell_color} text-center pl-3 whitespace-nowrap`,
							},
						},
					]}
					data={fillRateOverview?.block_schedule_details ?? []}
				/>
			)}
		</div>
	);
}

export default BlockFillRate;
