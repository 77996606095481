import * as Popover from '@radix-ui/react-popover';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import TextField from 'components/TextField';

export interface HeaderDropdownProps<Item> {
	activeText: string;
	filterFn: (i: Item, searchQuery: string) => boolean;
	renderTextFn: (i: Item) => React.ReactNode | null;
	renderNewFn?: () => React.ReactNode | null;
	label: string | ReactNode;
	options: Item[];
	onSelect: (item: Item) => void;
	isWorking?: boolean;
}

export function HeaderDropdown<Item>({
	activeText,
	label,
	options,
	filterFn,
	renderTextFn,
	renderNewFn,
	isWorking,
	onSelect: handleSelect,
}: HeaderDropdownProps<Item>) {
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [searchQuery, setSearchQuery] = useState('');

	// filter the list whenever the user is typing into the search field
	useEffect(() => {
		setFilteredOptions(
			options.filter((item: Item) => {
				return filterFn(item, searchQuery);
			})
		);
	}, [filterFn, options, searchQuery]);

	// local utility to clear the search query
	const clearSearchQuery = useCallback(() => {
		setSearchQuery('');
	}, [setSearchQuery]);

	return (
		<div className='flex relative xl:mr-2'>
			<Popover.Root
				onOpenChange={() => {
					clearSearchQuery();
				}}
			>
				<div className='flex items-center'>
					<span>{label}</span>
					<Popover.Trigger
						className={classNames(
							'flex items-center justify-between w-[200px] ml-2 py-1',
							'border-b border-blue-500 text-blue-500'
						)}
					>
						<span className='truncate'>{activeText}</span>
						<span className='material-symbol'>expand_more</span>
					</Popover.Trigger>
				</div>
				<Popover.Portal>
					<Popover.Content
						side='bottom'
						sideOffset={5}
						align='start'
						className='w-[250px] z-topMenu bg-white rounded border border-blue-500 drop-shadow-md'
					>
						<div className='p-2'>
							<TextField
								onInput={(e: React.KeyboardEvent<HTMLInputElement>) => {
									setSearchQuery((e.target as HTMLTextAreaElement).value);
								}}
								label=''
								icon='search'
								placeholder='Search by name'
								iconClass='text-blue-600'
								sizeY='md'
								isWorking={isWorking}
							/>
						</div>
						<ul className='text-p2 w-full pb-2 overflow-y-auto max-h-72'>
							{renderNewFn && <li>{renderNewFn()}</li>}
							{filteredOptions.map((item, index) => {
								return (
									<li
										key={index}
										onClick={() => {
											handleSelect(item);
										}}
									>
										<Popover.Close
											className={classNames(
												'w-full min-w-[125px] block my-1 p-3 py-2',
												'truncate text-left font-secondary font-regular text-gray-600',
												'hover:bg-blue-50'
											)}
										>
											{renderTextFn(item)}
										</Popover.Close>
									</li>
								);
							})}
						</ul>
					</Popover.Content>
				</Popover.Portal>
			</Popover.Root>
		</div>
	);
}

export default HeaderDropdown;
