import { NumberSchema } from 'yup';

import { StaffingGridItem } from 'pages/Anesthesia/components';
import { SetScheduledCaseMetadata, ShortOption } from 'store';

/**
 * Enum with binary index position (from end) of 16-bit int.
 * Example: core only would look like this 0000000000000001 and block only like this 0000000000000010
 */
export enum FacilityLicense {
	unknown = -1,
	core = 0,
	block = 1,
	anesthesia = 2,
	nurse_staffing = 3,
}

export const license16Bit = '0000000000000000';

// these are the only valid combinations of license types
export const reverseLicenseMap: { [key: number]: string } = {
	3: 'Nurse Staffing',
	2: 'Anesthesia',
	1: 'Block',
	0: 'Core',
};

export const defaultCaseElementsTemplate = {
	required: [
		'H&P',
		'Consent',
		'Labs Results in Chart',
		'Physician Orders',
		'Cardiac Clearance',
		'EKG Verified',
		'Authorization',
	],
	optional: [
		'Appropriate staffing',
		'Missing instruments',
		'Quick Turnover',
		'Equipment Conflict',
		'Equipment Borrowed',
		'Missing Implants',
		'Rep Notified',
	],
};

export const defaultCaseElements: SetScheduledCaseMetadata = {
	checked: [],
	comments: '',
};

export interface HolidayItem {
	is_custom?: boolean;
	name: string;
	id: number;
	selected?: boolean;
	created_at?: string;
	to_delete?: boolean;
}

/**
 * Model of room objects as they appear in our database tables
 */
export interface Room {
	facility_id: number;
	id: number;
	is_active: boolean;
	licensed: boolean;
	name: string;
	run_id: number | null;
}

export interface RecordType {
	name: string;
	target: string;
}

/**
 * JSON structure to hold the field mapping of a facility
 */
export interface FieldMapping {
	client_provided_case_id?: string;
	patient_in_room?: string;
	patient_out_room?: string;
	room?: string;
	surgeon?: string;
	facility?: string;
	scheduled_start?: string;
	procedure_start?: string;
	procedure_stop?: string;
	scheduled_stop?: string;
	surgery_date?: string;
	anesthesia_start?: string;
	anesthesia_end?: string;
	primary_anesthesiologist?: string;
	primary_anesthetist?: string;
	asa_class?: string;
	anesthesia_ready?: string;
	procedure_description?: string;
	encounter_type?: string;
	service_line?: string;
	is_add_on?: string;
	case_create_date?: string;
	case_cancel_date?: string;
	delay_code?: string;
	delay_type?: string;
	delay_reason?: string;
	delay_duration?: string;
	scheduled_room?: string;
	cpt_code?: string;
	cpt_description?: string;
	primary_payor?: string;
	primary_circulator?: string;
	primary_cst?: string;
	primary_first_assist?: string;
	preop_in?: string;
	pacu_p1_in?: string;
	pacu_p1_out?: string;
	pacu_p2_in?: string;
	pacu_p2_out?: string;
	schedule$_case_id?: string;
	schedule$_line_number?: string;
	schedule$_record_type?: string;
	schedule$_cancel_date?: string;
	schedule$_service_line?: string;
	schedule$_case_create_date?: string;
	schedule$_surgery_date?: string;
	schedule$_procedure_start?: string;
	schedule$_procedure_stop?: string;
	schedule$_duration?: string;
	schedule$_primary_surgeon?: string;
	schedule$_facility?: string;
	schedule$_room?: string;
	schedule$_procedure_description?: string;
}

interface CaseElementObject {
	required: string[];
	optional: string[];
}

/**
 * Model of facility objects as they appear in our database tables
 */
export interface Facility {
	value: any;
	addon_rate_target: number;
	allocation_cutoff_weeks_length: number;
	allow_partial_release?: boolean;
	block_utilization_target?: number;
	csm_user_id: null;
	duration_error_limit: number;
	extract_date_from_patient_in: false;
	facility_utilization_target: number;
	fcots_target: number;
	fcots_timestamp: string;
	fcots_window_time_end: string;
	fcots_window_time_start: string;
	friday_prime_time_end: string;
	friday_prime_time_start: string;
	friday_rooms_count: number;
	has_intraop: boolean;
	has_scheduled: boolean;
	healthsystem_id: number;
	id: number;
	intraop_facility_name_alias: string;
	field_mapping: FieldMapping;
	license: string;
	license_activation: Date;
	license_expire: Date;
	monday_prime_time_end: string;
	crna_vacation_weeks: number;
	mda_vacation_weeks: number;
	crna_weekly_clinical_hours: number;
	mda_weekly_clinical_hours: number;
	medical_direction_model: string;
	monday_prime_time_start: string;
	monday_rooms_count: number;
	name: string;
	is_primetime_strict: false;
	release_precedence?: boolean;
	rooms: Room[];
	schedule_record_types?: RecordType[];
	holidays_included: HolidayItem[];
	procedure_filtering_options: string[];
	saturday_prime_time_end: string;
	saturday_prime_time_start: string;
	saturday_rooms_count: number;
	storage_path: string;
	sunday_prime_time_end: string;
	sunday_prime_time_start: string;
	sunday_rooms_count: number;
	thursday_prime_time_end: string;
	thursday_prime_time_start: string;
	thursday_rooms_count: number;
	tuesday_prime_time_end: string;
	tuesday_prime_time_start: string;
	tuesday_rooms_count: number;
	turnover_target: number;
	wednesday_prime_time_end: string;
	wednesday_prime_time_start: string;
	wednesday_rooms_count: number;
	draw_down_schedule?: StaffingGridItem[];
	planned_setup_time?: number;
	planned_cleanup_time?: number;
	case_elements?: CaseElementObject;
	rn_vacation_weeks: number;
	surgical_tech_vacation_weeks: number;
	variable_staff_vacation_weeks: number;
	indirect_staff_vacation_weeks: number;
	rn_weekly_clinical_hours: number;
	surgical_tech_weekly_clinical_hours: number;
	variable_staff_weekly_clinical_hours: number;
	indirect_staff_weekly_clinical_hours: number;
	productivity_lower_bound: number;
	productivity_upper_bound: number;
	state?: string | null;
	zip?: number | null;
	street_address?: string | null;
	city?: string | null;
	ehr?: string | null;
	cms_designation?: string | null;
	number_of_beds?: number | null;
}

/**
 * Model of healthsystem objects as they appear in our database tables
 */
export interface Healthsystem {
	allow_block_overbooking: boolean;
	block_utilization_facility_agnostic: boolean;
	city?: string;
	facilities: Facility[];
	id: number;
	name: string;
	state?: string | null;
	zip?: number | null;
	ehr?: string | null;
	cms_designation?: string | null;
	number_of_beds?: number | null;
	street_address?: string | null;
	total_licenses: number;
	total_users: number;
	total_facilities: number;
}

/**
 * User properties, modeled like the records in users table in database
 */
export interface UserInfo {
	default_facility: number;
	first_name: string;
	email: string;
	last_name: string;
	healthsystem_id: number;
	id: number;
	role: UserRole;
	facilities: { id: number; healthsystem_id: number }[];
	page_list: string[];
}

/**
 * Each of the various roles that a user can belong to or be assigned as in the application
 */
export enum UserRole {
	admin = 1, // "admins, super-admins" can do anything they want, practically
	delivery = 2, // "delivery" admins, can make changes to facilities on behalf of their clients/accounts
	delivery_basic = 3, // "delivery" read-only users - sales, demos, etc.
	healthsystem_admin = 4, // admins on the healthsystem side, only have access to one healthsystem per account
	facility_admin = 5, // admin within a facility, can make changes only to the facilities in which they are an admin, read-only in other facilities
	facility_basic = 6, // a "read-only" user role for core/block
}

/**
 * This holds metadata for a block
 */
export interface Block {
	id: number; // this is the block id. in the case of duplicate block names across healthsystems, this is used for uniqueness
	service_line_id: number; // id of the service line the block belongs to
	name: string; // name of the block
	healthsystem_id: number; // id of healthsystem the block operates within
}

/**
 * All of these control the block settings for a healthsystem. Currently they are only limited to admin/SD internal users
 * Eventually we plan to roll these out to external healthsystem users
 */
export interface BlockSetting {
	block_utilization_facility_agnostic: boolean; // this controls if a block can work in any facility within the healthsystem
	allow_block_overbooking: boolean; // determines if there will be a warning or error message when a user submits a block pattern where there's surgeon overlap
}
export interface FacilityBlockSetting {
	facility_id: number;
	release_precedence: boolean | undefined; // if true, release won't be counted if there's a case record within block for any block surgeon. if false, we are counting the release even if that happens
	allow_partial_release?: boolean | undefined; // only allow partial release if this is checked
	block_utilization_target: number | undefined; // block utilization targer percentage forblock pages
}

/**
 * This holds metadata for a surgeon
 */
export interface Surgeon {
	id: number; // this is the surgeon id. in the case of duplicate surgeon names across healthsystems, this is used for uniqueness
	name: string; // name of the surgeon
	healthsystem_id: number; // id of healthsystem the surgeon operates within
	run_id: number; // indicates the pipeline run that this surgeon was found in
	is_active: number; // indicates if they are active or not
}

/**
 * Enum with the correct integer value (as stored in database) for each addOns
 */
export enum addOnsToNumber {
	'Scheduled' = 0,
	'Add-Ons' = 1,
	'All' = 2,
}

/**
 * Enum with the correct integer value (as stored in database) for each Primetime
 */
export enum PrimetimeToNumber {
	'Non-Primetime' = 0,
	'Primetime' = 1,
	'All' = 2,
}
