import {
	LineSegment,
	Selection,
	VictoryAxis,
	VictoryBar,
	VictoryLabel,
	VictoryStack,
	VictoryTooltip,
	VictoryZoomContainer,
} from 'victory';
import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	Accordion,
	Button,
	ButtonLink,
	ChartLegend,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	Select,
} from 'components';
import { border, determineHeight, fontFamily } from 'utils';
import { useAppSelector } from 'store';
import { ProcedurePatternObject, useGetProcedurePatternsQuery } from 'store/services/ProcedurePatterns';
import { useFilters, useToast } from 'context';

const colors = {
	GRAYLIGHT: '#EAF0F0',
	BLUE: '#106ff4',
	GRAYDARK: '#DEE8E8',
};

const legend = [
	{
		label: 'Pat-In to Proc-Start',
		color: colors.GRAYDARK,
	},
	{
		label: 'Procedure Time',
		color: colors.BLUE,
	},
	{
		label: 'Proc-Stop to Pat-Out',
		color: colors.GRAYLIGHT,
	},
];

const SORT_BY_OPTIONS = [
	{
		label: 'Case Time (Low-High)',
		value: 'time_ascending',
	},
	{
		label: 'Case Time (High-Low)',
		value: 'time_descending',
	},
	{
		label: 'Wheels In to Proc Start (Low-High)',
		value: 'wheels_in_to_proc_start_ascending',
	},
	{
		label: 'Wheels In to Proc Start (High-Low)',
		value: 'wheels_in_to_proc_start_descending',
	},
	{
		label: 'Proc End to Wheels Out) (Low-High)',
		value: 'proc_end_to_wheels_out_ascending',
	},
	{
		label: 'Proc End to Wheels Out) (High-Low)',
		value: 'proc_end_to_wheels_out_descending',
	},
	{
		label: 'Procedure Duration (Low-High)',
		value: 'procedure_duration_ascending',
	},
	{
		label: 'Procedure Duration (High-Low)',
		value: 'procedure_duration_descending',
	},
];

const GROUP_BY_OPTIONS = [
	{
		label: 'None',
		value: 'none',
	},
	{
		label: 'Year',
		value: 'year',
	},
];

const VIEW_BY_OPTIONS = [
	{
		label: 'Day of Week',
		value: 'day_of_week',
	},
	{
		label: 'Month',
		value: 'month',
	},
	{
		label: 'Quarter',
		value: 'quarter',
	},
	{
		label: 'Surgeon',
		value: 'surgeon',
	},
	{
		label: 'Service Line',
		value: 'service_line',
	},
	{
		label: 'Procedure Description',
		value: 'procedure_description',
	},
];

const MODE_OPTIONS = [
	{
		label: 'Raw',
		value: 'raw',
	},
	{
		label: 'Avg',
		value: 'average',
	},
	{
		label: 'Ratio',
		value: 'ratio',
	},
];

class CustomFlyout extends React.Component<{
	x2?: number;
	y2?: number;
	datum?: { procedure_end: number; procedure_start: number; procedure_time: number; row_name: string };
	mode?: string;
}> {
	render() {
		const { x2, y2, datum, mode } = this.props;
		const total_bar_length = (datum?.procedure_start ?? 0) + (datum?.procedure_time ?? 0) + (datum?.procedure_end ?? 0);
		const start_ratio = Math.round(((datum?.procedure_start ?? 0) / total_bar_length) * 100);
		const end_ratio = Math.round(((datum?.procedure_end ?? 0) / total_bar_length) * 100);
		const in_procdure_ratio = Math.round(((datum?.procedure_time ?? 0) / total_bar_length) * 100);

		return (
			<foreignObject x={x2} y={y2} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.row_name}</p>
					</div>
					{mode === 'raw' && (
						<>
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Total</p>
								<p className='text-right text-[0.25em] text-white'>{total_bar_length.toFixed(0)} hr</p>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels In</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{start_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{(datum?.procedure_start ?? 0).toFixed(0)} hr</p>
								</div>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>In Procedure</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{in_procdure_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{datum?.procedure_time.toFixed(0)} hr</p>
								</div>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels Out</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{end_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{(datum?.procedure_end ?? 0).toFixed(0)} hr</p>
								</div>
							</div>
						</>
					)}
					{mode === 'average' && (
						<>
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Total</p>
								<p className='text-right text-[0.25em] text-white'>{total_bar_length} m</p>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels In</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{start_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{(datum?.procedure_start ?? 0).toFixed(0)} m</p>
								</div>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>In Procedure</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{in_procdure_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{(datum?.procedure_time ?? 0).toFixed(0)} m</p>
								</div>
							</div>
							<div className='bg-blue-900 p-1 flex flex-col justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels Out</p>
								<div className='bg-blue-900 pt-1 flex justify-between'>
									<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{end_ratio}%</p>
									<p className='text-right text-[0.25em] text-white opacity-80'>{(datum?.procedure_end ?? 0).toFixed(0)} m</p>
								</div>
							</div>
						</>
					)}
					{mode === 'ratio' && (
						<>
							{' '}
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels In</p>
								<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{start_ratio}%</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>In Procedure</p>
								<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{in_procdure_ratio}%</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Wheels Out</p>
								<p className='whitespace-nowrap text-left text-[0.25em] text-white opacity-80'>{end_ratio}%</p>
							</div>
						</>
					)}
				</div>
			</foreignObject>
		);
	}
}

class CustomLabel extends React.Component<{
	x?: number;
	y?: number;
	x2?: number;
	y2?: number;
	datum?: { procedure_end: number; procedure_start: number; procedure_time: number; row_name: string };
	mode?: string;
}> {
	static defaultEvents = [
		{
			target: 'data',
			eventHandlers: {
				onMouseOver: (evt: React.SyntheticEvent<Element, Event>) => {
					const { x, y } = Selection.getSVGEventCoordinates(evt);
					return {
						target: 'labels',
						mutation: () => ({
							// The label will not change position, but the tooltip will change position
							x2: x,
							y2: y,
							active: true,
							fontSize: 0,
						}),
					};
				},
				onMouseOut: () => {
					return { target: 'labels', mutation: () => ({ active: false }) };
				},
			},
		},
	];

	render() {
		// This is where we pass the new x,y for the tooltip
		const { x2, y2, mode } = this.props;
		return (
			<g>
				<VictoryLabel
					{...this.props}
					verticalAnchor='middle'
					dx={5}
					style={{ fontFamily: fontFamily, fontSize: 4.5, fontWeight: 600, fill: 'rgb(16, 111, 244)' }}
				/>
				<VictoryTooltip
					{...this.props}
					pointerLength={0}
					flyoutComponent={<CustomFlyout x2={x2} y2={y2} mode={mode} />}
					style={{ fontSize: 0 }}
				/>
			</g>
		);
	}
}

export function ProcedurePatterns() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);

	// Filters
	const {
		dateRange,
		daysOfWeek,
		surgeons,
		serviceLines,
		encounterTypes,
		rooms,
		procedures,
		primetime,
		addOns,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
		filtersAreFetching,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/procedure-patterns') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	// default: view by month
	const selectedViewBy = dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy : VIEW_BY_OPTIONS[0];
	// default: sort by time, descending (newest data -> oldest data)
	const selectedSortBy = dropDowns.sortBy.value !== 'undefined' ? dropDowns.sortBy : SORT_BY_OPTIONS[2];
	// default: group by year
	const selectedGroupBy = dropDowns.groupBy.value !== 'undefined' ? dropDowns.groupBy : GROUP_BY_OPTIONS[0];
	const selectedMode = dropDowns.mode?.value !== 'undefined' ? dropDowns.mode : MODE_OPTIONS[0];
	const groupByOptions = GROUP_BY_OPTIONS;

	const {
		data: procedureData,
		isFetching,
		error,
	} = useGetProcedurePatternsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			sortby: selectedSortBy.value,
			groupby: selectedGroupBy.value,
			viewby: selectedViewBy.value,
			mode: selectedMode?.value,
			filters: {
				days_of_week: daysOfWeek?.applied,
				surgeons: surgeons?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				primetime: primetime?.applied,
				add_ons: addOns?.applied,
			},
		},
		{
			skip: skipRequest || filtersAreFetching,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	let exportData;
	if (procedureData?.data) {
		exportData = procedureData.data
			.map((group) => {
				const items = group.values.map((v) => {
					// we need any for exporting here
					// eslint-disable-next-line
					const row: any = {
						procedure_end: v.pattern.procedure_end,
						procedure_start: v.pattern.procedure_start,
						procedure_time: v.pattern.procedure_time,
					};
					const key = selectedViewBy.value.replace('_id', '');
					row[key] = v.label;
					if (selectedGroupBy.value !== 'none') {
						row[selectedGroupBy.value] = group.groupValue;
					}
					return row;
				});

				return items;
			})
			.flat();
	}

	const data = procedureData?.data ?? [];
	let maxHour = 100;

	if (selectedMode.value === 'raw' || selectedMode.value === 'average') {
		maxHour =
			Math.max(
				...(data.map((res_obj, i) =>
					Math.max(
						...(res_obj.values.map(
							(inner_obj, i) =>
								inner_obj.pattern.procedure_time + inner_obj.pattern.procedure_start + inner_obj.pattern.procedure_end
						) ?? [1])
					)
				) ?? [1])
			) / 60;
	}

	const isGrouped = selectedGroupBy?.value !== 'none';

	return (
		<div className='relative container mx-auto mb-48'>
			<div className='flex justify-between'>
				<PageHeading>Procedure Patterns</PageHeading>
			</div>

			<div className='relative'>
				{(isFetching || skipRequest) && <LogoOverlay backgroundColor='white' />}
				<div className='flex justify-end pb-3 items-center'>
					<div className='flex text-center bg-blue-500 h-10 items-center justify-center gap-0 rounded-md mr-2'>
						<span className='text-b3 font-semibold px-6 text-white'>
							{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
						</span>
					</div>
					<Drawer
						metadata={metadata}
						filtersAreDirty={filtersAreDirty}
						trigger={
							<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
								<span className='material-symbol'>filter_alt</span>
								Filters
							</Button>
						}
						quickActions={[
							{
								icon: 'undo',
								onClick: resetFilters,
								tooltipText: 'Discard unapplied filter changes',
								disabled: !filtersAreDirty,
							},
							{
								icon: 'restart_alt',
								onClick: clearFilters,
								tooltipText: 'Reset filters to default',
								disabled: !metadata.saved_at,
							},
						]}
						actionButtons={[
							{
								onClick: applyFilters,
								children: 'Apply',
								disabled: !filtersAreDirty,
							},
							{
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick: () => {},
								children: 'Views',
								disabled: false,
							},
						]}
					>
						<FilterFields
							fields={[
								'dateRange',
								'surgeons',
								'daysOfWeek',
								'serviceLines',
								'encounterTypes',
								'rooms',
								'primetime',
								'addOns',
								'procedures',
							]}
							dateRangeLimit={1}
						/>
					</Drawer>
					<ExportButton contents={exportData ?? []} />
				</div>
				<Panel title='' goToHelpID='commonDefinitions' noHeader isEmpty={procedureData?.data?.length === 0}>
					<div className='flex justify-between items-center'>
						<div className='flex gap-4'>
							<div className='w-36'>
								<Select
									label='View by'
									onChange={(selected) => {
										if (selected) {
											const optionInViewBy = VIEW_BY_OPTIONS.find((element) => element.value === selected.value);
											if (optionInViewBy) {
												let newGroupBy: { label: string; value: string } | undefined = undefined;
												if (optionInViewBy.value === 'day_of_week') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												} else if (optionInViewBy.value === 'service_line') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												} else if (optionInViewBy.value === 'block_name') {
													newGroupBy = {
														label: 'None',
														value: 'none',
													};
												}

												if (newGroupBy) {
													dropDowns.update({ ...dropDowns, groupBy: newGroupBy, viewBy: optionInViewBy });
													saveDropdown({ ...dropDowns, groupBy: newGroupBy, viewBy: optionInViewBy });
												} else {
													dropDowns.update({ ...dropDowns, viewBy: optionInViewBy });
													saveDropdown({ ...dropDowns, viewBy: optionInViewBy });
												}
											}
										}
									}}
									value={selectedViewBy}
									options={VIEW_BY_OPTIONS}
									defaultValue={selectedViewBy}
								/>
							</div>
							<div className='w-64'>
								<Select
									label='Sort by'
									options={SORT_BY_OPTIONS}
									value={selectedSortBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, sortBy: selected });
											saveDropdown({ ...dropDowns, sortBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Group by'
									options={groupByOptions}
									value={selectedGroupBy}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, groupBy: selected });
											saveDropdown({ ...dropDowns, groupBy: selected });
										}
									}}
								/>
							</div>
							<div className='w-36'>
								<Select
									label='Mode'
									options={MODE_OPTIONS}
									value={selectedMode}
									onChange={(selected) => {
										if (selected) {
											dropDowns.update({ ...dropDowns, mode: selected });
											saveDropdown({ ...dropDowns, mode: selected });
										}
									}}
								/>
							</div>
						</div>
						<div>
							<ChartLegend options={legend} />
						</div>
					</div>

					{isGrouped && data.length > 0 ? (
						<Accordion className='my-8' type='multiple' defaultValue={data.map((group) => `${group.groupValue}`)}>
							{data.map((group, i) => {
								return (
									<Accordion.Item key={i} value={`${group.groupValue}`} dark>
										<div className='h-full w-full'>
											<ProcedureBarChart values={group.values} maxHour={maxHour} mode={selectedMode.value} />
										</div>
									</Accordion.Item>
								);
							})}
						</Accordion>
					) : (
						data.map((group, i) => {
							return (
								<div key={i} className='h-full w-full'>
									<ProcedureBarChart values={group.values} maxHour={maxHour} mode={selectedMode.value} />
								</div>
							);
						})
					)}
				</Panel>
			</div>
		</div>
	);
}

export default ProcedurePatterns;

interface ReleaseBarChartsProps {
	values: ProcedurePatternObject['values'];
	maxHour: number;
	mode: string;
}

interface procedureTimeType {
	row_name: string;
	x: string;
	y: number;
	total_used: number;
	total_hours: number;
	procedure_end: number;
	procedure_start: number;
	procedure_time: number;
}

interface procedureStartType {
	row_name: string;
	x: string;
	y: number;
	procedure_end: number;
	procedure_start: number;
	procedure_time: number;
}

interface procedureEndType {
	row_name: string;
	x: string;
	y: number;
	max: number;
	procedure_end: number;
	procedure_start: number;
	procedure_time: number;
}

function ProcedureBarChart({ values, maxHour, mode }: ReleaseBarChartsProps) {
	let procedureStart: procedureStartType[];
	let procedureTime: procedureTimeType[];
	let procedureEnd: procedureEndType[];

	if (mode === 'ratio') {
		procedureStart = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `automaticRelease_${i}`,
				y: obj.pattern.procedure_start,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
		procedureTime = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedureTime_${i}`,
				y: obj.pattern.procedure_time,
				total_used: Math.round(
					((obj.pattern.procedure_time - obj.pattern.procedure_start - obj.pattern.procedure_end) /
						obj.pattern.procedure_time) *
						100
				),
				total_hours: (obj.pattern.procedure_time + obj.pattern.procedure_start) / 60,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
		procedureEnd = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedure_end_${i}`,
				y: obj.pattern.procedure_end,
				max: obj.pattern.procedure_end,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
	} else if (mode === 'average') {
		procedureStart = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `automaticRelease_${i}`,
				y: obj.pattern.procedure_start / 60,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
		procedureTime = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedureTime_${i}`,
				y: obj.pattern.procedure_time / 60,
				total_used: Math.round(
					((obj.pattern.procedure_time - obj.pattern.procedure_start - obj.pattern.procedure_end) /
						obj.pattern.procedure_time) *
						100
				),
				total_hours: (obj.pattern.procedure_time + obj.pattern.procedure_start) / 60,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
		procedureEnd = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedure_end_${i}`,
				y: obj.pattern.procedure_end / 60,
				max: obj.pattern.procedure_end / 60,
				procedure_end: obj.pattern.procedure_end,
				procedure_start: obj.pattern.procedure_start,
				procedure_time: obj.pattern.procedure_time,
			};
		});
	} else {
		procedureStart = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `automaticRelease_${i}`,
				y: obj.pattern.procedure_start / 60,
				procedure_end: obj.pattern.procedure_end / 60,
				procedure_start: obj.pattern.procedure_start / 60,
				procedure_time: obj.pattern.procedure_time / 60,
			};
		});
		procedureTime = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedureTime_${i}`,
				y: obj.pattern.procedure_time / 60,
				total_used: Math.round(
					((obj.pattern.procedure_time - obj.pattern.procedure_start - obj.pattern.procedure_end) /
						obj.pattern.procedure_time) *
						100
				),
				total_hours: (obj.pattern.procedure_time + obj.pattern.procedure_start) / 60,
				procedure_end: obj.pattern.procedure_end / 60,
				procedure_start: obj.pattern.procedure_start / 60,
				procedure_time: obj.pattern.procedure_time / 60,
			};
		});
		procedureEnd = values.map((obj, i) => {
			return {
				row_name: obj.label,
				x: `procedure_end_${i}`,
				y: obj.pattern.procedure_end / 60,
				max: obj.pattern.procedure_end / 60,
				procedure_end: obj.pattern.procedure_end / 60,
				procedure_start: obj.pattern.procedure_start / 60,
				procedure_time: obj.pattern.procedure_time / 60,
			};
		});
	}

	const avg = Math.round(procedureTime.reduce((r, c) => r + c.x.toString().length, 0) / values.length);
	const regx = new RegExp('.{1,avg}(?:\\s|$)'.replace('avg', avg > 20 ? (avg - 2).toString() : '18'), 'g');

	const [zoomState, setZoomState] = useState<{ y: [number, number] }>({ y: [0, maxHour * 1.15] });

	// keep domain updated
	useEffect(() => {
		setZoomState({ y: [0, maxHour * 1.15] });
	}, [maxHour]);

	// zoom in by 1 hour on either side of the chart
	const handleClickZoomIn = useCallback(() => {
		const [start, end] = zoomState.y;
		const newStart = 0;
		// eslint-disable-next-line prettier/prettier
		const newEnd = end - end * 0.25;
		setZoomState({ y: [newStart, newEnd] });
	}, [zoomState, setZoomState]);

	// un-magnify by 1 hour on either side of the chart
	const handleClickZoomOut = useCallback(() => {
		const [start, end] = zoomState.y;
		// eslint-disable-next-line prettier/prettier
		const newEnd = end + end * 0.25;
		setZoomState({ y: [0, newEnd] });
	}, [zoomState, setZoomState]);

	// reset to default state, same as on a page refresh
	const handleClickReset = useCallback(() => {
		setZoomState({ y: [0, maxHour * 1.15] });
	}, [maxHour]);

	return (
		<>
			<div className={'flex items-center justify-end ml-4 pl-4'}>
				<ButtonLink className='mr-3' onClick={handleClickReset}>
					Reset Zoom
				</ButtonLink>
				<Button onClick={handleClickZoomOut} sizeY='sm' sizeX='square' variant='primary-ghost' className='mr-2'>
					<span className='material-symbol'>zoom_out</span>
				</Button>
				<Button onClick={handleClickZoomIn} sizeY='sm' sizeX='square' variant='primary-ghost'>
					<span className='material-symbol'>zoom_in</span>
				</Button>
			</div>
			<VictoryStack
				colorScale={[colors.GRAYDARK, colors.BLUE, colors.GRAYLIGHT]}
				horizontal
				style={{ labels: { fontSize: 5, fontFamily: 'inter' } }}
				height={determineHeight(values.length, true)}
				domain={{ x: [0.5, values.length + 0.5], y: [0, maxHour * 1.15] }}
				padding={{ top: 25, bottom: 10, right: 10, left: avg > 20 ? avg * 2.8 : 60 }}
				containerComponent={
					<VictoryZoomContainer
						onZoomDomainChange={(domain) => {
							// domain is { x: DomainTuple, y: DomainTuple }
							// not quite sure how to convert that to numbers?
							const [start, end] = domain.y;
							if (typeof start === 'number' && typeof end === 'number') {
								const newStart = 0;
								const newEnd = 60 * Math.ceil(end / 60.0);
								// TODO: it would probably be much more performant to do a debounce here, after like 50-100ms of delay
								// but didn't have time to do that when first implementing
								setZoomState({
									y: [0, newEnd],
								});
							}
						}}
						allowPan={true}
						allowZoom={false}
						zoomDomain={zoomState}
						zoomDimension='y'
					/>
				}
			>
				<VictoryBar
					data={procedureStart}
					barWidth={12.5}
					labelComponent={<CustomLabel mode={mode} />}
					labels={({ datum }) => ` `}
				/>
				<VictoryBar
					data={procedureTime}
					barWidth={12.5}
					labels={({ datum }) => ``}
					labelComponent={<CustomLabel mode={mode} />}
				/>
				<VictoryBar
					data={procedureEnd}
					barWidth={12.5}
					cornerRadius={3}
					labels={({ datum }) => {
						let label = Math.round(datum.procedure_time);

						// in raw mode convert small hours to minutes, in this case <= 2 hours
						if (label <= 2 && mode === 'raw') {
							label = Math.round(datum.procedure_time * 60);
							return `${label} m`;
						}

						if (mode === 'ratio') {
							return `${Math.round(datum.procedure_time)}%`;
						} else if (mode === 'average') {
							return `${Math.round(datum.procedure_time)} m`;
						} else {
							return ` ${Math.round(datum.procedure_time)} h`;
						}
					}}
					style={{
						labels: { fontSize: 3, fontFamily: 'inter', fontWeight: 600, paddingTop: 100, fill: 'rgb(16, 111, 244)' },
					}}
					labelComponent={<CustomLabel mode={mode} />}
				/>

				<VictoryAxis
					standalone
					tickValues={values.map((obj) => obj.label)}
					tickFormat={values.map((d) => d.label.match(regx))}
					tickLabelComponent={<VictoryLabel style={{ fontSize: 5, fontFamily: 'inter' }} />}
					axisComponent={<LineSegment style={{ stroke: border }} />}
				/>
				<VictoryAxis
					dependentAxis
					tickLabelComponent={<VictoryLabel style={{ fontSize: 5, fontFamily: 'inter' }} />}
					axisComponent={<LineSegment style={{ stroke: border }} />}
					tickFormat={(x) => {
						if (mode === 'average' || mode === 'raw') {
							return `${x} hrs`;
						} else {
							return `${x}%`;
						}
					}}
					padding={{ top: 0 }}
					gridComponent={<LineSegment style={{ stroke: border, padding: { bottom: 100 } }} />}
					orientation='top'
				/>
			</VictoryStack>
		</>
	);
}
